import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/Layout";
import SeO from "../components/SEO";

function about() {
  return (
    <Layout>
      <SeO title="About" />
      <section className="pageContainer about">
        <div className="about-container">
          <StaticImage
            src="../assets/user.png"
            alt="home image"
            className="about-img"
            placeholder="blurred"
          />
          <div className="aboutContent">
            <h2 className="about-title">about codingsumit </h2>
            <div className="underline"></div>
            <p className="about-paragraph">
              My name is sumit , I am a content & web developer from india 👋 🇮🇳
              creator of
              <a href="https://www.instagram.com/coding.batch/">
                {" "}
                coding.batch{" "}
              </a>
              (developer community)
            </p>
            <p className="about-paragraph">
              👨‍💻 I created this blog to share web dev tutorial , tips and tricks
              because I enjoy helping beginners and sharing what I know in web
              development with the intention of contributing to community 🛡️
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default about;
